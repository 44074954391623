<template>
  <v-container>
    <perseu-card title="Gerenciar Agendamentos">
      <v-flex slot="content">
        <filters-panel>
          <v-form @submit.prevent="list">
            <v-row justify="center">
              <v-col>
                <v-autocomplete
                  v-model="filter.status"
                  :items="allItemns"
                  outlined
                  chips
                  small-chips
                  multiple
                >
                </v-autocomplete>

                <v-text-field
                  filled
                  v-model.trim="filter.therapist"
                  label="Terapeuta"
                />

                <v-text-field
                  filled
                  v-model.trim="filter.patient"
                  label="Paciente"
                />
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  @click="clearQuery"
                  outlined
                  color="secondary"
                  class="mr-3"
                >
                  Limpar
                </v-btn>
                <v-btn @click="list" color="secondary"> Buscar </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </filters-panel>
        <v-data-table
          :items="appointments"
          :headers="headers"
          class="elevation-1"
          :options.sync="options"
          :server-items-length="total"
        >
          <template v-slot:item.actions="props">
            <v-btn icon @click="edit(props.item)" color="secondary">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <v-dialog v-model="dialog" width="800">
      <appointment-form :appointment="appointment" @update="dialog = false">
      </appointment-form>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  find,
  findOne,
  getAllStatus,
} from "@/services/appointment-management-service";
export default {
  components: {
    "appointment-form": () =>
      import("@/components/AppointmentManagement/DetailedAppointment"),
  },
  data: () => ({
    filter: {
      active: true,
      therapist: "",
      patient: "",
      status: [],
    },
    allItemns: [],
    appointment: {},
    appointments: [],
    headers: [
      { text: "ID", value: "id" },
      { text: "Terapeuta", value: "therapist.name" },
      { text: "Paciente", value: "patient.name" },
      { text: "Status", value: "status" },
      { text: "Ações", value: "actions" },
    ],
    dialog: false,
    options: {},
    total: 0,
  }),
  watch: {
    options: {
      handler() {
        this.list();
      },
      deep: true,
    },
  },
  methods: {
    create() {
      this.appointment = {};
      this.dialog = true;
    },
    async edit(item) {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findOne(item.id);
        this.appointment = data;
        this.dialog = true;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let { data } = await find({
          ...this.options,
          filter: this.getFilter(),
        });
        this.appointments = data.items;
        this.total = data.total;
        let statusLocal = await getAllStatus();
        this.allItemns = statusLocal.data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    getFilter() {
      let filter = {};
      filter.status = this.getStatus();
      filter.therapist = this.filter.therapist;
      filter.patient = this.filter.patient;

      return filter;
    },
    getStatus() {
      return this.filter.status;
    },
    clearQuery() {
      this.filter = {
        active: true,
        therapist: "",
        patient: "",
      };
    },
  },
};
</script>

<style></style>
