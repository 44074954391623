import api from "./api-service";
import { stringify } from "querystring";

const baseUrl = "appointments-management";

export const find = (filter) => api().post(`${baseUrl}/find`, filter);

export const findOne = (id) => api().get(`${baseUrl}/${id}`);

export const getAllStatus = () => api().get(`${baseUrl}/getAllStatus`);
